import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { AboutHeader, BannerLeft, BannerButton } from "../utils"
import img from "../images/bcg/v-day-banner.jpg"

const ValentinesPage = () => (
  <Layout>
    <Seo
      title="Valentine's Day at azalea bar & kitchen"
      keywords={[`Valentine's Day in Asheville, NC near Biltmore`]}
    />
    <AboutHeader img={img}>
      <BannerLeft title="Valentine's Day Special" subtitle="Reservations suggested">
        <hr />
        <p style={{color: '#fff'}}>
          Dine with us on Valentine's Day and enjoy 3 lovely courses for $35 per person.
        </p>
        <BannerButton>
          <a style={{fontFamily: "sans-serif"}} href="/valentines-day-menu.pdf">View the Menu</a>
        </BannerButton>
      </BannerLeft>
    </AboutHeader>
  </Layout>
)

export default ValentinesPage
